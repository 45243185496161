<template>
  <v-container fluid fill-height class="text-center">
    <v-row>
      <v-col cols="12">
        <v-img :src="logo" max-height="90" contain/>
      </v-col>
    </v-roW>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'home',
  data: () => ({
    logo: require('@/assets/logo.png'),
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    modulos: []
  }),
  methods: {
  }
}
</script>

<style scoped>
</style>
